<template>
  <div class="mini-game-base">
    <div class="wrapper">
      <nav :class="['navbar', turnHappening ? 'hide' : '']">
        <div class="nav-links-left">
          <div class="back-button">
            <router-link class="back" to="/"
              ><BlueButton size="small">Back</BlueButton></router-link
            >
          </div>
        </div>
        <img
          class="logo"
          src="../../assets/legend-of-aurum-draconis.png"
          alt="logo"
        />
        <div class="nav-links-right">
          <!-- <router-link class="link" to="/mint-games/dragon-fire-lottery"
            >Dragon Fire Lottery</router-link
          > -->
          <div class="account-connect">
            <div class="account-section connect-button">
              <ConnectButton size="small" />
            </div>
          </div>
        </div>
      </nav>
      <router-view />
    </div>
  </div>
  <Notifications class="notification-wrapper" />
</template>

<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

// import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import Notifications from "../../components/Notifications.vue";
import ConnectButton from "../../components/Buttons/ConnectButton.vue";
import BlueButton from "../../components/Buttons/BlueButton.vue";

// import { ethers } from "ethers";
// import { parseError } from "../../utils/helpers";

// import { useUser } from "../../composables/user";
export default {
  displayName: "MintGameBase",
  components: {
    BlueButton,
    ConnectButton,
    Notifications,
    // GenericLoading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    // const revealButton = ref(null);
    const isLoading = ref(false);
    const isLoadingInitialData = ref(true);

    const isLoggedIn = ref(false);
    const profileImage = ref("");
    // const isBurningToken = ref(false);
    // const { address, signer } = useUser();

    onMounted(async () => {
      // router.push("/mint-games/dcar-burn-battle");

      isLoadingInitialData.value = true;
      // if (isSessionActive.value) {
      // }
      isLoadingInitialData.value = false;
    });

    onUnmounted(() => {
      unWatchSessionActive();
    });

    const unWatchSessionActive = store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          isLoadingInitialData.value = true;
          isLoadingInitialData.value = false;
        }
      }
    );

    const goToGame = () => {
      router.push("/");
    };
    const refresh = () => {
      window.location.reload();
    };

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    const turnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    return {
      // data
      isLoading,
      isLoadingInitialData,
      isLoggedIn,
      profileImage,
      // address,
      // method
      refresh,
      goToGame,
      // computed
      isSessionActive,
      turnHappening,
    };
  },
};
</script>
<style lang="scss">
body {
  background-color: transparent;
}
/* body {
  background-color: transparent;
  &:hover {
    &:before {
      transform: scale(2);
    }
  }
  &:before {
    background: #2c1c10 url("../../assets/burning-bg.png");
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    transition: all 1s eas-in-out;
  }
} */
.mini-game-base {
  font-family: "Cormorant", serif;
  width: 100vw;
  height: 100vh;
  /* background: #2c1c10 url("../../assets/burning-bg.png"); */
  /* background-position: top;
  background-attachment: fixed;
  background-size: cover; */

  /* .wrapper {
    padding-top: 60px;
  } */
}
.wrapper {
  padding-bottom: 3rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(81, 81, 81, 0.5) 0%,
    rgba(181, 181, 181, 0.5) 50%,
    rgba(81, 81, 81, 0.5) 100%
  );
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.3),
    0 3px 7px 0 rgba(0, 0, 0, 0.3);
  padding: 22px 0;
  height: 28px;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 50;
  .connect-button {
    @media only screen and (max-width: 576px) {
      position: absolute;
      top: -55px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }
  .back-button {
    position: absolute !important;
    top: 25px;
    left: 25px;
  }
  &.hide {
    background: transparent;
    border: none;
    box-shadow: none;
    .nav-links-left,
    .nav-links-right {
      opacity: 0;
    }
    .logo {
      max-width: 400px;
      top: 30px;
      position: relative;
      @media only screen and (max-width: 576px) {
        max-width: 240px;
      }
    }
  }
  .nav-links-left,
  .nav-links-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37%;
  }

  .nav-links-left {
    align-items: flex-end;
    justify-content: flex-end;
  }

  .nav-links-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: left;
    .account-connect {
      width: 97%;
      display: grid;
      justify-items: right;
    }
  }

  .link {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    color: #ffd6ab;
    text-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
    font-size: 18px;
  }
  .back {
    text-decoration: none;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .logo {
    max-width: 340px;
    transition: all 0.5s ease;
    @media only screen and (max-width: 576px) {
      max-width: 200px;
    }
  }
  .navbar .nav-links::before,
  .navbar .nav-links::after {
    content: "";
    flex: 1;
  }

  .navbar .nav-links::before {
    margin-right: 0.5em;
  }

  .navbar .nav-links::after {
    margin-left: 0.5em;
  }

  .account-section {
    display: flex;
    align-items: center;
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.content {
  /* margin: 56px auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Cormorant", serif; */

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Cormorant", serif;
    color: #fff;
    font-weight: bold;
  }
}
</style>
